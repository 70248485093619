
import { mapState, mapMutations } from 'vuex'
import GlobalForm from '../components/PcGlobalForm.vue'
import dialogXXSuccess from './DIalogXXSuccess.vue'
const Base64 = require('js-base64').Base64
export default {
  name: 'LoginDialog',
  components: { GlobalForm, 'dialog-XX-success': dialogXXSuccess },
  data() {
    return {
      showPassword: false,
      xxContentVisible: false,
      xxContent: 'success',
      isRemember: false,
      showDeleteBtn: false,
      activeName: 'first',
      submitLoading: false,
      loginData: {
        email: '',
        password: '',
      },
      registData: {},
      // 表单配置
      loginFormConfig: [
        {
          autoInput: true,
          prop: 'email',
          type: 'input',
          placeholder: 'User Name',
        },
        {
          prop: 'password',
          type: 'password',
          placeholder: 'Password',
        },
        {
          type: 'slot',
          slotName: 'checkbox',
        },
      ],
      registFormConfig: [
        {
          long: true,
          prop: 'email',
          type: 'input',
          placeholder: 'Email',
        },
        {
          long: true,
          prop: 'password',
          type: 'password',
          placeholder: 'Password',
        },
        {
          long: true,
          prop: 'confirm_password',
          type: 'password',
          placeholder: 'Comfirm Password',
        },
        {
          prop: 'contacts',
          type: 'input',
          placeholder: 'First Name',
        },
        {
          prop: 'last_name',
          type: 'input',
          placeholder: 'Last Name',
        },
        {
          long: true,
          prop: 'company',
          type: 'input',
          placeholder: 'Company Name',
        },
        {
          prop: 'phone',
          type: 'input',
          placeholder: 'Phone No',
        },
        {
          prop: 'url',
          type: 'input',
          placeholder: 'Website',
        },
        {
          long: true,
          prop: 'address',
          type: 'input',
          placeholder: 'Address',
        },
        {
          prop: 'state',
          type: 'input',
          placeholder: 'State',
        },
        {
          prop: 'postcode',
          type: 'input',
          placeholder: 'Post Code',
        },
      ],
      // 表单规则
      loginRules: {
        email: [
          {
            required: true,
            message: 'Please enter the correct email',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'The password length only allows 6-16 bits',
            trigger: 'blur',
            min: 6,
            max: 16,
          },
        ],
      },
      registRules: {
        email: [
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'The password length only allows 6-16 bits',
            trigger: 'blur',
            min: 6,
            max: 16,
          },
        ],
        confirm_password: [
          {
            required: true,
            message: 'The password length only allows 6-16 bits',
            trigger: 'blur',
            min: 6,
            max: 16,
          },
        ],
        company: [
          {
            required: true,
            message: 'Please enter your company name',
            trigger: 'blur',
          },
        ],
        contacts: [
          {
            required: true,
            message: 'Please enter your contact person',
            trigger: 'blur',
          },
        ],
        last_name: [
          {
            required: true,
            message: 'Please enter your contact person',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please enter your phone number',
            trigger: 'blur',
          },
        ],
      },
      autoComplete: null,
      // 记录谷歌地图API选择地点后拿到的地址名. 这个不能直接更新到registeData上, 不然输入框内容会再次变化.
      selectAddress: '',
    }
  },
  computed: {
    ...mapState(['dialogVisible', 'config']),
    dialogWidth() {
      return this.activeName === 'first' ? '506px' : '1108px'
    },
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        document.addEventListener('keydown', this.onEnterClick)
      } else {
        document.removeEventListener('keydown', this.onEnterClick)
      }
    },
    activeName() {
      if (this.activeName === 'second' && this.autoComplete === null) {
        if (window.google) {
          this.initGoogleMapAutoComplete()
          this.getAddrByLocation()
        } else {
          setTimeout(() => {
            this.initGoogleMapAutoComplete()
            this.getAddrByLocation()
          }, 1500)
        }
      }
    },
  },
  mounted() {
    this.getCookie()
  },
  methods: {
    ...mapMutations(['closeDialog', 'openDialog']),
    onEnterClick(e) {
      if (e.keyCode === 13) {
        console.log('enter')
        let target = this.$refs.loginForm
        if (this.activeName === 'first') {
          // do nothing. login form
        }
        if (this.activeName === 'second') {
          target = this.$refs.registForm
        }
        if (typeof target.submit === 'function') {
          target.submit()
        }
      }
    },
    checkRegisterForm() {
      this.$refs.registForm.validate(valid => {
        if (valid) {
          this.submit()
        }
      })
    },
    submit() {
      if (this.activeName === 'first') {
        if (this.isRemember) {
          const password = Base64.encode(this.loginData.password)
          this.setCookie(this.loginData.email, password, 7)
        } else {
          this.setCookie('', '', -1)
        }
        this.login()
      } else if (this.activeName === 'second') {
        this.regist()
      }
    },
    login() {
      this.$store
        .dispatch('login', this.loginData)
        .then(res => {
          if (res.code == 1) {
            setTimeout(() => {
              this.closeDialog()
            }, 200)
            this.xxContentVisible = true
            this.xxContent = 'Login Successful'
            this.submitLoading = false

            // this.$confirm("Login Successful", {
            //   confirmButtonText: "OK",
            //   confirmButtonClass: 'confirmBtn',
            //   showCancelButton: false,
            //   showClose: false,
            //   type: "success",
            //   center: true,
            //   lockScroll: false,
            // }).then(() => {
            // });
          } else if (res.code == 10100) {
            this.$confirm(res.msg, {
              confirmButtonText: 'OK',
              showCancelButton: false,
              type: 'error',
              center: true,
            })
          }
        })
        .catch(() => {
          this.closeDialog()
        })
    },
    regist() {
      this.submitLoading = true
      const formData = Object.assign({}, this.registData)
      if (this.selectAddress && this.selectAddress.length) {
        formData.address = this.selectAddress
      }
      this.$axios({
        url: '/au/register',
        method: 'post',
        data: formData,
      })
        .then(res => {
          if (res.code == 1) {
            this.closeDialog()
            this.submitLoading = false
            this.xxContentVisible = true
            this.xxContent =
              "We've received your login request. Someone will be in touch shortly to confirm."
            this.registerEmail()
          }
        })
        .catch(e => {
          const res = e.response.data
          if (res.code === 10100) {
            this.$confirm(res.msg + ', please click OK to jump to login.', {
              confirmButtonText: 'OK',
              showCancelButton: false,
              type: 'error',
              center: true,
            }).then(() => {
              this.submitLoading = false
              this.activeName = 'first'
            })
          }
        })
    },
    registerEmail() {
      this.$axios
        .get('/member/register_email?email=' + this.registData.email)
        .then(res => {})
        .catch(error => {
          this.$message.error(error.response.data.msg)
        })
    },
    close() {
      this.closeDialog()
      this.registData = {}
    },
    resetPassword() {
      this.closeDialog()
      this.$refs.resetPasswordDialog.resetDialogVisible = true
    },
    openLoginDialog() {
      this.openDialog()
    },
    // 设置cookie
    setCookie(email, password, days) {
      const date = new Date()
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days)
      document.cookie =
        'email' + '=' + email + ';path=/;expires=' + date.toGMTString()
      document.cookie =
        'password' + '=' + password + ';path=/;expires=' + date.toGMTString()
    },

    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ')
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=')
          if (arr2[0] === 'email') {
            this.loginData.email = arr2[1]
          } else if (arr2[0] === 'password') {
            this.loginData.password = Base64.decode(arr2[1])
            this.isRemember = true
          }
        }
      }
    },
    toSignatureLink() {
      const link = this.config.configInfo?.link || ''
      if (link) {
        window.open(link, '_blank')
      }
    },
    initGoogleMapAutoComplete() {
      // console.log(window.google.maps, 'maps')
      // https://developers.google.com/maps/documentation/javascript/place-autocomplete
      this.autoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById('address_input'),
        {
          types: ['address'],
          componentRestrictions: {
            country: ['AU'],
          },
          // 如果不知道需要什么字段, 可以查文档, 或给'all'或不传fields参数, 然后从返回结果里面找出需要数据的字段名, 填到这里
          fields: [
            'address_components',
            'place_id',
            'name',
            'formatted_address',
          ],
        }
      )

      this.autoComplete.addListener('place_changed', this.onPlaceChange)
    },
    onPlaceChange() {
      const data = this.autoComplete.getPlace()

      if (Array.isArray(data.address_components)) {
        data.address_components.forEach(i => {
          if (i.types.includes('postal_code')) {
            this.$set(this.registData, 'postcode', i.long_name || i.short_name)
          }
          if (i.types.includes('administrative_area_level_1')) {
            this.$set(this.registData, 'state', i.short_name || i.long_name)
          }
        })
      }
      this.selectAddress = data.name || this.registData.address
    },
    onInputChange(value, attr) {
      if (attr === 'address') {
        // 地址输入变更时, 清空这个值, 避免手动输入地址又不选的时候, 这个变量保存了上一次选地址时留下的数据.
        this.selectAddress = ''
      }
    },
    getAddrByLocation() {
      if (navigator.geolocation) {
        let location = {
          lat: 0,
          lng: 0,
        }
        navigator.geolocation.getCurrentPosition(
          position => {
            location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            console.log(location, 'position')
            const geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({ location }).then(response => {
              console.log(response, 'response')
              if (response.results?.length) {
                response.results[0].address_components.forEach(i => {
                  if (i.types.includes('postal_code')) {
                    this.$set(
                      this.registData,
                      'postcode',
                      i.long_name || i.short_name
                    )
                  }
                  if (i.types.includes('administrative_area_level_1')) {
                    this.$set(
                      this.registData,
                      'state',
                      i.short_name || i.long_name
                    )
                  }
                  if (i.types.includes('route')) {
                    this.$set(
                      this.registData,
                      'address',
                      i.short_name || i.long_name
                    )
                  }
                  this.selectAddress = i.short_name || i.long_name
                })
              }
            })
          },
          error => {
            console.log(error, 'get location error')
          }
        )
      }
    },
  },
}
